<template>
  <v-app style="background-color: #333">
    <v-main>
      <v-container class="fill-height" fluid>
        <v-row align="center" justify="center" no-gutters>
          <v-col style="max-width: 800px" class="text-center mb-15">
            <v-icon color="white" size="45" dark>mdi-phone-hangup</v-icon>
            <h2 style="color: #ccc" class="font-weight-light mt-2">
              {{ $capitalize($tc("model.video_call_ended_call")) }}
            </h2>
            <h4 style="color: #ccc" class="font-weight-light mt-2">
              Por favor feche esta aba
            </h4>
          </v-col>
        </v-row>
      </v-container>
      <v-footer
        v-if="false"
        absolute
        class="text-center"
        style="background: none; bottom: 55px"
      >
        <v-card-text>
          <h4 class="white--text font-weight-light">
            {{ $capitalize($tc("model.video_call_question_service")) }}
          </h4>
          <v-rating
            color="orange"
            background-color="orange lighten-2"
            size="30"
          ></v-rating>
        </v-card-text>
      </v-footer>
    </v-main>
  </v-app>
</template>

<script>
export default {
  methods: {
    handleReconect() {
      this.$router.go(-1);
    },
  },
};
</script>
